<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @input="changeForm()">
          <b-card-header class="d-flex justify-content-start mb-1">
            <span
              class="font-weight-bold"
              style="color: #fefefe; font-size: 16px"
            >{{ $t("GestionGrupos") }} </span>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <div>
                  <b-form-group
                    :label="$t('PermisosUsuario')"
                    label-for="permissionGroups"
                  >
                    <v-select
                      id="permissionGroups"
                      v-model="selectedPermissionGroups"
                      :options="permissionGroups"
                      multiple
                      label="name"
                      aria-autocomplete="new-password"
                      autocomplete="new-password"
                      :selectable="(option) => forbid ? !option.name.includes(constants.administrador) : true"
                      @input="changeForm()"
                    />
                  </b-form-group>
                </div>
                <div v-if="!isEmpty(selectedPermissionGroups) ? includesPermission(constants.vendor) : false">
                  <b-form-group
                    :label="$t('shopUser')"
                    label-for="profileVendor"
                  >
                    <v-select
                      id="profileVendor"
                      v-model="selectedProfileVendor"
                      :options="vendorGroups"
                      label="vendorName"
                      :placeholder="$t('shopUser')"
                      aria-autocomplete="new-password"
                      autocomplete="new-password"
                      @input="changeForm()"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-group
                    v-if="subgroups"
                    :label="$t('Subgroup')"
                    label-for="subgroup"
                  >
                    <v-select
                      id="subgroup"
                      v-model="subgroup"
                      :options="subgroups"
                      label="name"
                      :placeholder="$t('SinGrupoUsuario')"
                      aria-autocomplete="new-password"
                      autocomplete="new-password"
                      @input="changeForm()"
                    />
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="!isEmpty(selectedPermissionGroups) ? includesPermission(constants.sponsor) : false"
              class="mt-1"
            >
              <b-col>
                <admin-sponsor-categories @categoriesSelected="categoriesSelected" />
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <b-button
                variant="success"
                :disabled="disabled"
                class="mb-1 mb-sm-0  float-right mt-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="clck()"
              >
                <feather-icon icon="SaveIcon" />
                {{ $t("dataGeneric.save") }}
              </b-button>
            </div>
          </b-card-body>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import * as constants from '@core/utils/constants'

import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BCardHeader,
  BCardBody,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive' // Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import selectOptions from '@/libs/utils/v-select-from-reflection'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import { isEmpty, isUserInGroup } from '@/store/functions'
import i18n from '@/libs/i18n'
import AdminSponsorCategories from '../administrators/AdminSponsorCategories.vue'
// import userStoreModule from '../administrators/administratorsStoreModule'

let forbid = false
let isShop = false
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    AdminSponsorCategories,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref(null)
    const subgroups = ref(null)
    const subgroup = ref(null)
    const permissionGroups = ref([])
    const selectedPermissionGroups = ref(null)
    const vendorGroups = ref(null)
    const selectedProfileVendor = ref({})
    const introspectionData = ref({})

    store
      .dispatch('admin-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data.allProfiles.edges[0].node

        const arrayExclude = getUserData().isSuperuser ? [] : constants.exludeList
        const allGroups = response.data.data.allGroups.edges
          .filter(group => {
            const { name } = group.node
            return (
              !arrayExclude.includes(name) // Evita los nombres en arrayExclude
              && ((name === constants.vendor && isShop) // Condición para vendor
                || (name === constants.administrador && !forbid) // Condición para administrador
                || (name !== constants.vendor && name !== constants.administrador)) // Cualquier otro nombre
            )
          })
          .map(element => ({
            node: {
              id: element.node.id,
              name: element.node.name,
            },
          }))

        const vendors = response.data.data.allVendors.edges
        const tempArray = []
        const vendArray = []
        const tempObject = {}
        allGroups.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.node.name = i18n.t(element.node.name)
          tempArray.push(element.node)
        })
        vendors.forEach(element => {
          vendArray.push(element.node)
        })
        permissionGroups.value = tempArray

        vendorGroups.value = vendArray

        const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups.edges

        const userVendor = response.data.data.allProfiles.edges[0].node.profileVendor.edges
        subgroup.value = response.data.data.allProfiles.edges[0].node.subgroup
        try {
          const nodesArray = userGroups.map(group => group.node)

          selectedPermissionGroups.value = nodesArray
          // console.log('selectedPermissionGroups.value => ', selectedPermissionGroups.value)

          if (forbid) {
            if (selectedPermissionGroups.value.name === constants.administrador) {
              router.push({ name: 'misc-not-authorized' })
            }
          }
          // eslint-disable-next-line no-empty
        } catch (error) {

        }
        selectedProfileVendor.value = userVendor.length === 0 ? null : userVendor[0].node
        // console.log('selectedProfileVendor.value => ', selectedProfileVendor.value)

        Object.entries(response.data.data.allProfiles.edges[0].node).forEach(
          ([key, value2]) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
            response.data.data.__type.fields.forEach(value => {
              if (key === value.name) {
                const mvalue = value
                mvalue.value = value2
                tempObject[key] = mvalue
              }
            })
          },
        )
        introspectionData.value = tempObject

        store
          .dispatch('admin-user/fetchSubGroups').then(result => {
            const { edges } = result.data.data.allSubgroups
            subgroups.value = edges.map(e => e.node)
          }).catch(err => {
            console.error(err)
          })
      })
      .catch(error => {
        console.error(error)
        if (error.status === 404) {
          userData.value = undefined
        }
      })

    const mutateUser = categories => {
      simpleRules.value.validate().then(success => {
        if (success) {
          if (userData.value.cpassword === null) { userData.value.password = null }
          const idsArray = selectedPermissionGroups.value.map(item => item.id)
          store
            .dispatch('admin-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
              groups: idsArray,
              vendor: selectedProfileVendor.value,
              subgroup: subgroup.value?.id,
              categories,
              querySelect: 'UpdateAdmin',
              view: 'Admin',
            })
            .then(response => {
              if (response[0].data.data && response[1].data.data) {
                // console.log('Ok permisos')
                // Comprobar en que orden llegan las respuestas
                // Extrae datos y verifica las respuestas de forma más compacta
                const profile1 = response[0]?.data?.data?.updateProfile?.profile
                const customUser1 = response[0]?.data?.data?.updateCustomuser?.customUser
                const profile2 = response[1]?.data?.data?.updateProfile?.profile
                const customUser2 = response[1]?.data?.data?.updateCustomuser?.customUser

                // Verifica en qué orden llegaron las respuestas y asigna userData
                if (profile1 || customUser1) {
                  userData.value = profile1 || customUser1
                  // console.log('userData.value -> ', userData.value)
                }

                if (profile2 || customUser2) {
                  userData.value = profile2 || customUser2
                  // console.log('userData.value -> ', userData.value)
                }
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('error'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              console.error(error)
              toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t('error'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          let i = 0
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            if (i === 0) {
              i += 1
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: i18n.t('confirmPassword'),
                },
              })
            }
            return key
          })
        }
      })
    }

    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userDataBlank = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userDataBlank.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { getValidationState } = formValidation(resetuserData)

    return {
      userData,
      subgroups,
      subgroup,
      getValidationState,
      permissionGroups,
      selectedPermissionGroups,
      selectedProfileVendor,
      vendorGroups,
      introspectionData,
      selectOptions,
      mutateUser,
      simpleRules,
    }
  },
  data() {
    return {
      router,
      forbid,
      isShop,
      constants,
      isEmpty,
      disabled: true,
      categories: [],
    }
  },
  /* beforeDestroy() {
    if (store.hasModule('admin-user')) store.unregisterModule('admin-user')
  },
  beforeCreate() {
    if (!store.hasModule('admin-user')) store.registerModule('admin-user', userStoreModule)
  }, */
  mounted() {
    const user = getUserData()
    const loguinUserAdmin = isUserInGroup(user, constants.administrador)

    try {
      if (!loguinUserAdmin) {
        forbid = true
      } else {
        forbid = false
      }

      isShop = user.profile.client.isShop
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  },
  methods: {
    categoriesSelected(data) {
      this.disabled = false
      this.categories = data
    },
    changeForm() {
      this.disabled = false
    },
    includesPermission(string) {
      return this.selectedPermissionGroups.some(item => item.name === string)
    },
    clck() {
      this.mutateUser(this.categories)
      this.disabled = !this.disabled
    },
  },
}
</script>
<style lang="sass" scoped>

</style>
